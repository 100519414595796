<template>
    <zw-sidebar @shown="shown"
                :title="this.payload.id ? $t('common.form.title.editCustomField')  + ' ('+ this.payload.id + ')' : $t('common.form.title.createCustomField')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <b-row>
                    <b-col cols="4">
                        <zw-select-group v-model="form.model"
                                         :options="getModels()"
                                         name="model"
                                         validate="required"
                                         :label-prefix="labelPrefix"
                        ></zw-select-group>
                    </b-col>
                    <b-col cols="4">
                        <zw-select-group v-model="form.type"
                                         :options="['text','date','textarea','number','select','boolean','radio','wysiwyg']"
                                         name="type"
                                         validate="required"
                                         :label-prefix="labelPrefix"
                        ></zw-select-group>
                    </b-col>
                    <b-col cols="4">
                        <zw-input-group v-model="form.name"
                                        name="name"
                                        validate="required"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="4">
                        <zw-input-group v-model="form.options.label"
                                        name="label"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="4">
                        <zw-input-group v-model="form.order"
                                        name="order"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="4">
                        <zw-input-group v-model="form.options.placeholder"
                                        name="placeholder"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="3">
                        <zw-input-group v-model="form.options.group"
                                        name="group"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="1">
                        <zw-switch-group v-model="form.options.disable_label"
                                         name="disable_label"
                                         :label-prefix="labelPrefix"
                                         v-b-tooltip.hover :title="this.$t('custom_fields.tooltip.disable_label')"
                        ></zw-switch-group>
                    </b-col>
                    <b-col cols="1">
                        <zw-switch-group v-model="form.options.readonly"
                                         name="readonly"
                                         v-b-tooltip.hover :title="this.$t('custom_fields.tooltip.readonly')"
                                         :label-prefix="labelPrefix"
                        ></zw-switch-group>
                    </b-col>
                    <b-col cols="1">
                        <zw-switch-group v-model="form.options.required"
                                         name="required"
                                         :label-prefix="labelPrefix"
                                         v-b-tooltip.hover :title="this.$t('custom_fields.tooltip.required')"
                        ></zw-switch-group>
                    </b-col>
                    <b-col cols="1" v-if="form.type == 'date'">
                        <zw-switch-group v-model="form.options.actual_dates"
                                         name="actual_dates"
                                         :label-prefix="labelPrefix"
                                         v-b-tooltip.hover :title="this.$t('custom_fields.tooltip.actual_dates')"
                        ></zw-switch-group>
                    </b-col>
                    <b-col cols="1" v-if="form.type == 'select'">
                        <zw-switch-group v-model="form.options.multiselect"
                                         name="multiselect"
                                         :label-prefix="labelPrefix"
                                         v-b-tooltip.hover :title="this.$t('custom_fields.tooltip.multiselect')"
                        ></zw-switch-group>
                    </b-col>
                    <b-col cols="1" v-if="form.model == 'offering'">
                        <zw-switch-group v-model="form.options.main_field"
                                         name="main_field"
                                         :label-prefix="labelPrefix"
                                         v-b-tooltip.hover :title="this.$t('custom_fields.tooltip.main_field')"
                        ></zw-switch-group>
                    </b-col>
                    <b-col cols="1" v-if="form.model == 'customer'">
                        <zw-switch-group v-model="form.options.show_in_activity"
                                         name="show_in_activity"
                                         :label-prefix="labelPrefix"
                                         v-b-tooltip.hover :title="this.$t('custom_fields.tooltip.show_in_activity')"
                        ></zw-switch-group>
                    </b-col>

                </b-row>
                <b-row v-if="['select','radio'].includes(form.type)">
                    <b-col cols="12">
                        <b-card :header="$t('custom_fields.group.select_options')">
                            <b-card-text>
                                <b-col cols="12">
                                    <b-row>
                                        <b-col cols="4">{{ $t('custom_fields.label.select_option_label') }}</b-col>
                                        <b-col cols="4">{{ $t('custom_fields.label.select_option_value') }}</b-col>
                                    </b-row>
                                    <b-row v-for="(option,key) in selectOptions"
                                           :key="key"
                                    >
                                        <b-col cols="4">
                                            <zw-input-group v-model="option.label"
                                                            name="select_option_label"
                                                            disable-label
                                                            :label-prefix="labelPrefix"
                                            ></zw-input-group>
                                        </b-col>
                                        <b-col cols="4">
                                            <zw-input-group v-model="option.value"
                                                            name="select_option_value"
                                                            disable-label
                                                            :label-prefix="labelPrefix"
                                            ></zw-input-group>
                                        </b-col>
                                        <b-col cols="4">
                                            <b-button
                                                @click="removeSelectOption(key)"
                                                size="sm"
                                                variant="danger"
                                                :title="$t('common.title.delete')"
                                            >
                                                <font-awesome-icon icon="trash"/>
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="12">
                                    <b-button
                                        @click="addSelectOption()"
                                        size="sm"
                                        variant="success"
                                        class="mr-2"
                                        :title="$t('common.title.add')"
                                    >
                                        <font-awesome-icon icon="plus"/>
                                    </b-button>
                                </b-col>
                            </b-card-text>
                        </b-card>
                    </b-col>
                </b-row>

                <b-row v-if="form.model == 'customer'">
                    <b-col cols="4">
                        <b-form-group>
                            <template v-slot:label>
                                {{ $t('custom_fields.label.only_for_types') }}
                            </template>
                            <multiselect
                                v-model="form.options.only_for"
                                :options="getCustomerTypes()"
                                :multiple="true"
                                select-label=""
                            >
                            </multiselect>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row v-if="form.model == 'offering'">
                    <b-col cols="4">
                        <b-form-group>
                            <template v-slot:label>
                                {{ $t('custom_fields.label.in_documents') }}
                            </template>
                            <b-input-group class="flex-nowrap">
                                <multiselect
                                    v-model="form.options.docs_option"
                                    :options="getDocumentTypes()"
                                    :multiple="true"
                                    select-label=""
                                >
                                </multiselect>
                                <b-input-group-append class="">
                                    <b-button variant="info"
                                              v-b-popover.hover.top="$t('custom_fields.tooltip.docs')"
                                    >
                                        <font-awesome-icon icon="info"/>
                                    </b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>

                    <b-col cols="4">
                        <b-form-group>
                            <template v-slot:label>
                                {{ $t('custom_fields.label.in_documents_info_block') }}
                            </template>
                            <b-input-group class="flex-nowrap">
                                <multiselect
                                    v-model="form.options.docs_info_block"
                                    :options="getDocumentTypes()"
                                    :multiple="true"
                                    select-label=""
                                >
                                </multiselect>
                                <b-input-group-append class="">
                                    <b-button variant="info"
                                              v-b-popover.hover.top="$t('custom_fields.tooltip.docs_info_block')"
                                    >
                                        <font-awesome-icon icon="info"/>
                                    </b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col sm="12" class="text-sm-right">
                        <b-button block @click="onSubmit()" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'CustomFieldModal',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            defaultForm: {
                options: {}
            },
            form: {},
            selectOptions: [],
            labelPrefix: 'custom_fields.label.',
        }
    },
    methods: {
        ...mapGetters('CustomFields', ['getField', 'getModels']),
        ...mapGetters('CommonData', ['getDocumentTypes', 'getCustomerTypes']),
        addSelectOption() {
            this.selectOptions.push({name: null, value: null})
        },
        removeSelectOption(index) {
            let options = this.selectOptions.filter((data, i) => {
                return i != index
            });
            this.$set(this, 'selectOptions', options)

        },
        shown() {
            let fetchMethod = 'fetchField';
            let data = {id: this.payload.id}
            if (this.payload.name && this.payload.model) {
                fetchMethod = 'fetchFieldByName'
                data = {name: this.payload.name, model: this.payload.model}
            }

            this.$store.dispatch('CustomFields/' + fetchMethod, data)
                .then(() => {
                    this.form = {
                        ...JSON.parse(JSON.stringify(this.defaultForm)),
                        ...JSON.parse(JSON.stringify(this.getField()))
                    }
                    if (this.form.options.options) {
                        Object.keys(this.form.options.options).forEach(key => {
                            this.selectOptions.push({value: key, label: this.form.options.options[key]})
                        })
                    }
                    if (Array.isArray(this.form.options)) {
                        this.form.options = {}
                    }
                    this.$refs['observer'].reset()
                })
                .finally(() => {
                    this.loading = false
                })
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true
                    if (['select', 'radio'].includes(this.form.type)) {
                        let selectOptions = {}
                        this.selectOptions.forEach(option => {
                            selectOptions['' + option.value] = option.label
                        })
                        this.form.options.options = selectOptions
                    }
                    this.$store.dispatch('CustomFields/saveField', this.form)
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .finally(() => {
                            this.loading = false;
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
    }
}
</script>